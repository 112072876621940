<template>
  <div class="lad-navbar">
    <div class="lad-navbar--wrapper">
      <div class="lad-navbar--hamburger">
        <Hamburger />
      </div>

      <LadNavigationLogo />

      <LadNavigationSearch :show="showSearch" />

      <LadNavigationMenu />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SearchNavigation',
  components: {
    Hamburger: () =>
      import(
        /* webpackChunkName: "lad-hamburger" */ '@/components/layout/Navigation/Hamburger'
      ),
    LadNavigationLogo: () =>
      import(/* webpackChunkName: "lad-navi-logo" */ './components/Logo'),
    LadNavigationSearch: () =>
      import(/* webpackChunkName: "lad-navi-search" */ './components/Search'),
    LadNavigationMenu: () =>
      import(/* webpackChunkName: "lad-navi-menu" */ './components/Menu'),
  },
  data() {
    return {
      showSearch: false,
    }
  },
  computed: {
    ...mapGetters('device', ['isMobile', 'isIOS']),
  },
  mounted() {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      this.showSearch = true
    }, 10)
  },
}
</script>

<style lang="scss">
.lad-navbar {
  background-color: white;
  border-bottom: 1px solid #ebeef2;

  &--wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr auto;

    @media (min-width: 992px) {
      grid-template-columns: auto 1fr auto auto;
    }
  }

  &--hamburger {
    padding-left: 20px;
    display: block;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .fa-user {
    font-size: 1.8em;
  }

  .lad-navigation {
    &--logo {
      padding: 20px 0 10px 20px;

      svg,
      .sprite-icons {
        max-width: 130px;
        height: 45px;
      }
    }

    &--menu {
      display: grid;
      grid-template-columns: auto auto auto auto;

      &--wrapper {
        display: grid;
        grid-template-columns: repeat(6, auto);
      }

      .el-menu.el-menu--horizontal {
        border: none;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        & > .el-menu-item,
        & > .el-submenu .el-submenu__title {
          line-height: 89px;
          height: 100%;
          color: #666;
        }

        & > .el-menu-item:hover,
        & > .el-submenu .el-submenu__title:hover {
          background-color: aliceblue;
        }

        & > .el-menu-item.is-active,
        & > .el-submenu.is-active .el-submenu__title {
          border: none;
        }
      }

      &--main,
      &--trips,
      &--destinations,
      &--contact {
        display: none !important;

        @media (min-width: 992px) {
          display: flex !important;

          .el-menu-item,
          .el-submenu__title {
            &,
            a {
              padding: 0 15px;
            }

            span {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 89px; /* 494.444% */
            }

            i.el-submenu__icon-arrow.el-icon-arrow-down {
              display: none !important;
            }
          }
        }
      }

      &--shopping-cart {
        display: none !important;

        @media (min-width: 992px) {
          display: flex !important;
        }
      }

      &--auth {
        display: flex !important;
      }

      &--contact {
        @media (min-width: 992px) {
          .el-submenu__title {
            i.fa {
              color: #409eff;
              font-size: 16px;
              font-style: normal;
              font-weight: 900;
              line-height: 14px; /* 87.5% */
              margin-right: 8px;
            }
          }
        }
      }

      &--main {
        @media (min-width: 992px) {
          .el-menu-item {
            i.fas {
              color: #199b39;
              font-size: 14px;
              font-style: normal;
              font-weight: 900;
              line-height: 14px; /* 100% */
              margin-right: 8px;
            }
          }
        }
      }

      &--language,
      &--currency {
        display: none !important;
      }
    }
  }
}

.lad-navigation--menu--contact--popper {
  .el-menu {
    padding: 0;
    display: flex;

    .el-menu-item {
      padding: 0 30px;
      height: auto;
    }

    .el-menu-item:first-child {
      padding-right: 20px;
      border-right: 1px solid #eee;
    }

    .el-menu-item:last-child {
      padding-left: 20px;
    }
  }
}
</style>
